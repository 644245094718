import { getRequestConfig } from 'next-intl/server';
import { notFound } from 'next/navigation';

// Can be imported from a shared config
export const SUPPORTED_LOCALES = ['en', 'fr'];

export default getRequestConfig(async ({ locale }: { locale: string }) => {
  // Validate that the incoming `locale` parameter is valid
  if (!SUPPORTED_LOCALES.includes(locale)) notFound();

  //
  // // Add continent codes too
  // WORLD: 'World',
  // AF: 'Africa', // Overrides Afghanistan
  // NA: 'North America', // Overrides Namibia
  // OC: 'Oceania',
  // AN: 'Antarctica',
  // AS: 'Asia', // Overrides American Samoa
  // EU: 'Europe',
  // SA: 'South America', // Overrides Saudi Arabia
  //
  const messages = {
    ...(
      (await import(`./messages/${locale}.json`)) as {
        default: Record<string, string>;
      }
    ).default,
  };
  return {
    messages,
  };
});
