// This component wraps next/link and automatically prefixes the href with the either the current locale or a locale the user is switching to as necessary.
// cf https://next-intl-docs.vercel.app/docs/routing/navigation#apis
import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { SUPPORTED_LOCALES } from '../i18n';

export const locales = SUPPORTED_LOCALES;
export const localePrefix = 'always'; // Default

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({ locales, localePrefix });
