'use client';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Icon from 'app/components/Icon';
import classNames from 'classnames';
import { useState } from 'react';

export type DefaultProps = {
  content: string;
  children?: React.ReactNode;
  top?: string;
  position?: 'left' | 'right';
  trigger?: 'click' | 'hover';
};
export const Default = ({
  content,
  children,
  position = 'left',
  top = 'top-7',
  trigger = 'click',
}: DefaultProps) => {
  const [hovered, setHovered] = useState(false);
  const handleOverIn = () => {
    if (trigger === 'hover') {
      setHovered(true);
    }
  };
  const handleOverOut = () => {
    if (trigger === 'hover') {
      setHovered(false);
    }
  };
  return (
    <Popover
      className="relative"
      onMouseEnter={handleOverIn}
      onMouseLeave={handleOverOut}
    >
      {({ open }) => (
        <>
          <PopoverButton className="focus:outline-none cursor-auto">
            <div
              className={classNames(
                'flex flex-row gap-1 transition-opacity duration-200',
                trigger !== 'hover' && 'cursor-pointer',
              )}
            >
              {trigger !== 'hover' && (
                <Icon
                  height={20}
                  width={20}
                  icon={{
                    source: 'local',
                    name: 'info-cr-fr',
                    type: 'solid',
                  }}
                  className=""
                />
              )}
              {children}
            </div>
          </PopoverButton>
          {((trigger === 'hover' && hovered) ||
            (trigger !== 'hover' && open)) && (
            <PopoverPanel
              static
              className={classNames(
                `absolute z-10 bg-white ${top} rounded shadow-md border border-gray-200 overflow-hidden text-center`,
                position === 'left' ? 'right-0' : 'left-0',
              )}
            >
              <div className="text-xs bg-carbon-800 text-white py-2 px-4">
                {content}
              </div>
            </PopoverPanel>
          )}
        </>
      )}
    </Popover>
  );
};
