import { Spinner } from 'app/components/Loader';
import { Tooltip } from 'app/components/Tooltip';
import classNames from 'classnames';
import type React from 'react';

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'invisible';
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const buttonClassNames = {
  primary: 'text-white bg-gray-700',
  secondary: 'text-gray-700 bg-white border-[1.5px] border-carbon-200',
  invisible: 'text-gray-700 bg-transparent hover:bg-gray-100 focus:bg-gray-100',
} as const;

const buttonClassNamesDisabled = {
  primary: 'text-gray-400 bg-gray-200',
  secondary: 'text-gray-400 bg-white border-[1.5px] border-carbon-200',
  invisible: 'text-gray-700 bg-transparent',
} as const;

type TooltipWrapperProps = {
  tooltip?: string;
  children: React.ReactNode;
};
const TooltipWrapper = ({ children, tooltip }: TooltipWrapperProps) => {
  if (!tooltip) return children;
  return (
    <Tooltip.Default top="top-9" trigger="hover" content={tooltip}>
      {children}
    </Tooltip.Default>
  );
};
export const Button = (props: ButtonProps) => {
  const { className, loading, ...rest } = props;
  const variant = props.variant || 'primary';

  let sizeClassName = 'h-9';

  switch (props.size) {
    case 'sm':
      sizeClassName = 'h-6 px-1 text-xs';
      break;
    case 'lg':
      sizeClassName = 'h-11 px-4 py-2.5 text-lg';
      break;
    default:
      sizeClassName = 'h-9 gap-2 px-3 py-1.5';
      break;
  }
  return (
    <TooltipWrapper tooltip={props.tooltip}>
      <button
        type="button"
        className={classNames(
          props.className,
          'flex flex-row align-center items-center justify-center rounded-md text-sm font-medium whitespace-nowrap',
          sizeClassName,
          props.disabled
            ? buttonClassNamesDisabled[variant]
            : buttonClassNames[variant],
          props.disabled && 'cursor-not-allowed',
        )}
        {...rest}
      >
        {loading && <Spinner height="h-5" width="w-5" />}
        {props.children}
      </button>
    </TooltipWrapper>
  );
};
